<template>
	<div class="cd-editor">
		<div class="cd-editor__leftbar">
			<div class="cd-editor__section filters-leftbar">
				<h3 class="filters-leftbar__main-title">{{ $t('filters') }}</h3>

				<section class="filters-leftbar__section">
					<h3 class="filters-leftbar__title">{{ $t('status') }}</h3>
					<label v-for="publication in publications" class="radio-button mb-10">
						<input type="radio" class="radio-button__input" v-model="publicationModel" :value="publication">
						<span class="radio-button__label">{{ $t(`publications.${publication}`)}}</span>
					</label>
				</section>

				<section class="filters-leftbar__section">
					<h3 class="filters-leftbar__title">{{ $t('type') }}</h3>
					<label v-for="type in courseTypes" class="checkbox mb-10" :class="{ 'checkbox--disabled': countCourses(type) === 0 }">
						<input type="checkbox" class="checkbox__input" v-model="courseTypesModel" :value="type" :disabled="countCourses(type) === 0">
						<span class="checkbox__label">{{ $t(`course_types.${type}`)}} ({{ countCourses(type) }})</span>
					</label>
				</section>

				<div class="filters-leftbar__section">
					<button @click="resetFilters" class="regular-btn white-btn" type="button">{{ $t('buttons.clear_filters') }}</button>
				</div>
			</div>
		</div>
		<div class="cd-editor__main">
			<div class="cd-editor__section cd-editor__section--top-row">
				<a href="/editor/courses/new?choose=1" class="regular-btn action-btn">+ {{ $t('buttons.add_course') }}</a>

				<div class="courses-filters">
					<section class="courses-filters__group courses-filters__group--select">
						<button class="courses-filters__btn" type="button">{{ sortingTitle }}</button>
						<div class="courses-filters__holder">
							<label v-for="(sortingItem, index) in sorting" :key="'sorting-' + index" class="course-select">
								<input
									class="course-select__input"
									type="radio"
									:value="sortingItem.value"
									v-model="sortingModel"
								>
								<span class="course-select__label">{{ sortingItem.title }}</span>
							</label>
						</div>
					</section>
				</div>
				<label class="cd-editor__search labeled-field labeled-field--search">
					<input
						:placeholder="$t('buttons.search')"
						type="text"
						class="labeled-field__input"
						ref="searchInput"
						@input="searchOnInput"
						v-model="searchModel"
					>
					<button
						class="remove-btn remove-btn--tiny remove-btn--red hide"
						type="button"
						ref="clearSearchButton"
						@click="clearSearch"
					></button>
				</label>
				<h3 class="light-font-color small-font-size ml-auto">{{ $t('total_courses') }}: {{ courses.length }}</h3>
			</div>
			<div class="cd-editor__section">
				<div class="cd-editor__list" ref="listCards">
					<template v-if="filteredCourses.length">
						<div  v-for="course in filteredCourses" :key="course.id" class="cd-editor__card cd-editor__card--course catalog-card catalog-card--editor">
							<a :href="'/courses/' + course.id" class="catalog-card__image-link">
								<img v-if="course.logo"
									:src="course.logo"
									:alt="course.id"
									class="catalog-card__image"
									loading="lazy"
								>
								<img v-else
									src="../../../static/images/courses/missing.png"
									:alt="course.id"
									class="catalog-card__image"
									loading="lazy"
								>
							</a>
							<div class="catalog-card__content">
								<h3 class="catalog-card__title">
									<a
										:href="`/courses/${course.id}`"
										ref="courseTitle"
										class="catalog-card__title-link"
										:title="course.name"
										target="_blank"
									>
										<template v-if="course.name">
											{{ course.name }}
										</template>
										<template v-else>
											<span class="light-font-color regular-font">{{ $t('no_name') }}</span>
										</template>
									</a>
								</h3>

								<div class="catalog-card__footer">
									<div v-if="course.is_published" class="small-font-size high-font-color">
										{{ $t('publications.published') }}
									</div>
									<div v-else class="small-font-size red-font-color">
										{{ $t('publications.unpublished') }}
									</div>
									<div class="catalog-card__show-more popover popover--small-card popover--hover popover--right">
										<button @mouseover="addClassForPopover($event)" class="popover__btn icon icon--main-font-color icon--tiny icon--box icon--show-more">
											<span class="visually-hidden">{{ $t('buttons.show_more') }}</span>
										</button>
										<div class="popover__panel">
											<ul>
												<li>
													<a class="popover__link" :href="`/courses/${course.id}`" target="_blank">
														{{ $t('buttons.open') }}
													</a>
												</li>
												<li>
													<a class="popover__link" :href="`/editor/courses/${course.id}/edit`">
														{{ $t('buttons.edit') }}
													</a>
												</li>
												<li>
													<a class="popover__link" :href="`/courses/${course.id}/statistics.xlsx`">
														{{ $t('buttons.download_statistics') }}
													</a>
												</li>
												<li>
													<a class="popover__link" :href="`/editor/courses/${course.id}/clone`" rel="nofollow" data-method="post">
														{{ $t('buttons.copy_course') }}
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
					<template v-else>
						<div class="light-font-color">{{ $t('messages.list_is_empty') }}</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CourseEditor',

	props: {
		courses: {
			type: Object,
			required: false
		},
	},

	data() {
		return {
			courseTypes: ['quiz', 'long', 'short', 'presentation', 'scorm'],
			courseTypesModel: [],
			publications: ['all', 'published', 'unpublished'],
			publicationModel: 'all',
			searchModel: '',
			sortingModel: 'order',
			sorting: [
				{ title: this.$t('sorting.new_first'), value: 'order'},
				{ title: this.$t('sorting.sorting_AZ'), value: 'sortingAZ'},
				{ title: this.$t('sorting.sorting_ZA'), value: 'sortingZA'},
			],
		}
	},

	computed: {
		sortingTitle() {
			return this.sorting.filter((item) => item.value === this.sortingModel).map(obj => obj.title)[0];
		},

		filteredCourses() {
			let courses = this.courses;

			if (this.sortingModel === 'order') {
				courses = courses.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
			}

			if (this.sortingModel === 'sortingAZ') {
				courses = courses.sort((a, b) => {
					if (!a.name) return 1;
					if (!b.name) return -1;
					return a.name.localeCompare(b.name)
				});
			}

			if (this.sortingModel === 'sortingZA') {
				courses = courses.sort((a, b) => {
					if (!a.name) return 1;
					if (!b.name) return -1;
					return b.name.localeCompare(a.name)
				});
			}

			if (this.courseTypesModel.length !== 0) {
				courses = courses.filter(
					(course) => this.courseTypesModel.includes(course.course_type)
				)
			}

			if (this.publicationModel === 'published') {
				courses = courses.filter((course) => course.is_published);
			}

			if (this.publicationModel === 'unpublished') {
				courses = courses.filter((course) => !course.is_published);
			}

			if (this.searchModel.length !== 0) {
				let query = this.searchModel.toLowerCase();

				courses = courses.filter((course) => {
					if (course.name) {
						return course.name.toLowerCase().includes(query)
					}
				})
			}

			return courses;
		},
	},

	methods:  {
		searchOnInput() {
			if (this.searchModel.length) {
				this.$refs.clearSearchButton.classList.remove('hide');
			} else {
				this.$refs.clearSearchButton.classList.add('hide');
			}
		},

		clearSearch() {
			this.$refs.searchInput.value = '';
			this.searchModel = '';
			this.$refs.clearSearchButton.classList.add('hide');
		},

		resetFilters() {
			this.courseTypesModel = [];
			this.publicationModel = 'all';
		},

		showStatistics(course) {
			return (course.course_type === 'quiz' || course.course_type === 'long' || course.course_type === 'scorm' || course.assessments) ? true : false;
		},

		countCourses(type) {
			return this.courses.filter(
				(course) => type.includes(course.course_type)
			).length
		},

		addClassForPopover(e) {
			let popover = e.target.closest('.popover');

			if (window.innerWidth - e.target.getBoundingClientRect().right > 200) {
				popover.classList.remove('popover--left');
				popover.classList.add('popover--right');
			} else {
				popover.classList.remove('popover--right')
				popover.classList.add('popover--left')
			}
		}
	}
}
</script>
