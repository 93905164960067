<template>
	<div class="cd-editor cd-editor--careers">
		<div class="cd-editor__leftbar">
			<div class="cd-editor__section filters-leftbar">
				<h3 class="filters-leftbar__main-title">{{ $t('filters') }}</h3>

				<section class="filters-leftbar__section">
					<h3 class="filters-leftbar__title">{{ $t('status') }}</h3>
					<label v-for="publication in publications" class="radio-button mb-10">
						<input type="radio" class="radio-button__input" v-model="publicationModel" :value="publication">
						<span class="radio-button__label">{{ $t(`publications.${publication}`)}}</span>
					</label>
				</section>

				<div class="filters-leftbar__section">
					<button @click="resetFilters" class="regular-btn white-btn" type="button">{{ $t('buttons.clear_filters') }}</button>
				</div>
			</div>
		</div>
		<div class="cd-editor__main">
			<div class="cd-editor__section cd-editor__section--top-row">
				<a href="/dashboard/careers/new" class="regular-btn action-btn">+ {{ $t('buttons.add_career') }}</a>

				<div class="courses-filters">
					<section class="courses-filters__group courses-filters__group--select">
						<button class="courses-filters__btn" type="button">{{ sortingTitle }}</button>
						<div class="courses-filters__holder">
							<label v-for="(sortingItem, index) in sorting" :key="'sorting-' + index" class="course-select">
								<input
									class="course-select__input"
									type="radio"
									:value="sortingItem.value"
									v-model="sortingModel"
								>
								<span class="course-select__label">{{ sortingItem.title }}</span>
							</label>
						</div>
					</section>
				</div>
				<label class="cd-editor__search labeled-field labeled-field--search">
					<input
						placeholder="Поиск"
						type="text"
						class="labeled-field__input"
						ref="searchInput"
						@input="searchOnInput"
						v-model="searchModel"
					>
					<button
						class="remove-btn remove-btn--tiny remove-btn--red hide"
						type="button"
						ref="clearSearchButton"
						@click="clearSearch"
					></button>
				</label>
				<h3 class="light-font-color small-font-size ml-auto">{{ $t('total_careers') }}: {{ careers.length }}</h3>
			</div>
			<div class="cd-editor__section cd-editor__section--with-cards">
				<div class="cd-editor__list">
					<template v-if="filteredCareers.length">
						<div v-for="(career, index) in filteredCareers" :key="career.id" class="cd-editor__card cd-editor__card--career profession-card profession-card--editor">
							<a :href="'/careers/' + career.id" class="profession-card__image-holder">
								<img v-if="career.logo"
									:src="career.logo"
									:alt="career.id"
									class="catalog-card__image"
									loading="lazy"
								>
								<img v-else
									src="../../../static/images/courses/missing.png"
									:alt="career.id"
									class="profession-card__image"
									loading="lazy"
								>
							</a>
							<div class="profession-card__content">
								<h3 class="profession-card__title">
									<a
										:href="`/careers/${career.id}`"
										ref="courseTitle"
										class="profession-card__title-link"
										:title="career.name"
										target="_blank"
									>
										<template v-if="career.name">
											{{ career.name }}
										</template>
										<template v-else>
											<span class="light-font-color regular-font">{{ $t('no_name') }}</span>
										</template>
									</a>
								</h3>
								<p class="profession-card__descr">{{ $t('hours_of_training', career.duration) }}</p>

								<div class="profession-card__footer">
									<div v-if="career.published" class="small-font-size high-font-color">
										{{ $t('publications.published') }}
									</div>
									<div v-else class="small-font-size red-font-color">
										{{ $t('publications.unpublished') }}
									</div>

									<div class="profession-card__show-more popover popover--small-card popover--hover">
										<button @mouseover="addClassForPopover($event)" class="popover__btn icon icon--main-font-color icon--tiny icon--box icon--show-more">
											<span class="visually-hidden">{{ $t('buttons.show_more') }}</span>
										</button>
										<div class="popover__panel">
											<ul>
												<li>
													<a class="popover__link" :href="`/careers/${career.id}`" target="_blank">
														{{ $t('buttons.open') }}
													</a>
												</li>
												<li>
													<a class="popover__link" :href="`/dashboard/careers/${career.id}`" target="_blank">
														{{ $t('buttons.edit') }}
													</a>
												</li>
												<li>
													<a class="popover__link" :href="`/dashboard/programs/new?assignment=true&amp;taskable_ids%5B%5D=Career%2F${career.id}`" target="_blank">
														{{ $t('buttons.assign') }}
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
					<template v-else>
						<div class="light-font-color">{{ $t('messages.list_is_empty') }}</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CareerEditor',

	props: {
		careers: {
			type: Object,
			required: false
		},
	},

	data() {
		return {
			publications: ['all', 'published', 'unpublished'],
			publicationModel: 'all',
			searchModel: '',
			sortingModel: 'order',
			sorting: [
				{ title: this.$t('sorting.new_first'), value: 'order'},
				{ title: this.$t('sorting.sorting_AZ'), value: 'sortingAZ'},
				{ title: this.$t('sorting.sorting_ZA'), value: 'sortingZA'},
			],
		}
	},

	computed: {
		sortingTitle() {
			return this.sorting.filter((item) => item.value === this.sortingModel).map(obj => obj.title)[0];
		},

		filteredCareers() {
			let careers = this.careers;

			if (this.sortingModel === 'order') {
				careers = careers.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
			}

			if (this.sortingModel === 'sortingAZ') {
				careers = careers.sort((a, b) => {
					if (!a.name) return 1;
					if (!b.name) return -1;
					return a.name.localeCompare(b.name)
				});
			}

			if (this.sortingModel === 'sortingZA') {
				careers = careers.sort((a, b) => {
					if (!a.name) return 1;
					if (!b.name) return -1;
					return b.name.localeCompare(a.name)
				});
			}

			if (this.publicationModel === 'published') {
				careers = careers.filter((career) => career.published);
			}

			if (this.publicationModel === 'unpublished') {
				careers = careers.filter((career) => !career.published);
			}

			if (this.searchModel.length !== 0) {
				let query = this.searchModel.toLowerCase();

				careers = careers.filter((career) => {
					if (career.name) {
						return career.name.toLowerCase().includes(query)
					}
				})
			}

			return careers;
		},
	},

	methods:  {
		searchOnInput() {
			if (this.searchModel.length) {
				this.$refs.clearSearchButton.classList.remove('hide');
			} else {
				this.$refs.clearSearchButton.classList.add('hide');
			}
		},

		clearSearch() {
			this.$refs.searchInput.value = '';
			this.searchModel = '';
			this.$refs.clearSearchButton.classList.add('hide');
		},

		resetFilters() {
			this.publicationModel = 'all';
		},

		addClassForPopover(e) {
			let popover = e.target.closest('.popover');

			if (window.innerWidth - e.target.getBoundingClientRect().right > 200) {
				popover.classList.remove('popover--left');
				popover.classList.add('popover--right');
			} else {
				popover.classList.remove('popover--right')
				popover.classList.add('popover--left')
			}
		}
	}
}
</script>
