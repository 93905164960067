const upsellPopups = [
	{
		"url": "academy-sales",
		"course": "Программа «Директор по продажам»",
		"progress": 30,
		"text": `<p class="bold-font">Ваш прогресс опережает 80% пользователей&nbsp;&mdash; вы&nbsp;уже освоили треть знаний и&nbsp;навыков, которые нужны руководителю отдела продаж!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;<span class="bold-font">программу &laquo;Директор по&nbsp;продажам&raquo;</span>, чтобы вы&nbsp;получили ещё больше карьерных преимуществ.</p>
				<p>Вам откроется 40 дополнительных уроков по&nbsp;стратегическому менеджменту, управлению рисками и&nbsp;работе с&nbsp;финансами, после которых вы&nbsp;сможете с&nbsp;уверенностью занимать самую высокую должность в&nbsp;отделе продаж.</p>
				<p class="bold-font">Для вас&nbsp;&mdash; особые условия &#128071;</p>`
	},
	{
		"url": "academy-sales-director",
		"course": "Курс «MBA: Управление продажами»",
		"progress": 30,
		"text": `<p class="bold-font">Ваш прогресс опережает 80% пользователей&nbsp;&mdash; вы&nbsp;уже освоили треть знаний и&nbsp;навыков, которые нужны директору по&nbsp;продажам!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курс &laquo;MBA: Управление продажами&raquo;</span>, чтобы вы&nbsp;получили ещё больше карьерных преимуществ.</p>
				<p>Вам откроется 3 новых блока&nbsp;&mdash; это 30 дополнительных уроков по&nbsp;операционному менеджменту, инвестициям, проектному мышлению и&nbsp;HR. После такого обучения вы&nbsp;сможете с&nbsp;уверенностью занимать самую высокую должность в&nbsp;отделе продаж.</p>
				<p class="bold-font">Для вас&nbsp;&mdash; особые условия &#128071;</p>`
	},
	{
		"url": "academy-com-director",
		"course": "Курс «Генеральный директор»",
		"progress": 30,
		"text": `<p class="bold-font">Ваш прогресс опережает 80% пользователей&nbsp;&mdash; вы&nbsp;уже освоили треть знаний и&nbsp;навыков, которые нужны коммерческому директору!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курс &laquo;Генеральный директор&raquo;</span>, чтобы вы&nbsp;вывели свою компанию на&nbsp;новый уровень.</p>
				<p>На&nbsp;этом обучении вы&nbsp;изучите все аспекты ведения бизнеса, сможете открыть своё дело или увеличить прибыльность уже существующего.</p>
				<p class="bold-font">Для вас&nbsp;&mdash; особые условия &#128071;</p>`
	},
	{
		"url": "academy-gen-director",
		"course": "Курс «Генеральный директор Премиум»",
		"progress": 30,
		"text": `<p class="bold-font">Ваш прогресс опережает 80% пользователей&nbsp;&mdash; вы&nbsp;уже освоили треть знаний и&nbsp;навыков, которые нужны генеральному директору!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курс &laquo;Генеральный директор Премиум&raquo;</span>, чтобы вы&nbsp;ещё глубже погрузились в&nbsp;тему.</p>
				<p>Вас ждут 54 дополнительных урока по&nbsp;стратегическому менеджменту, финансам и&nbsp;работе с&nbsp;командой&nbsp;&mdash; лекции, с&nbsp;которыми вы&nbsp;выведете управление компанией на&nbsp;новый уровень.</p>
				<p class="bold-font">Для вас&nbsp;&mdash; особые условия &#128071;</p>`
	},
	{
		"url": "academy-accountant-beginner",
		"course": "Курс «Бухгалтер»",
		"progress": 30,
		"text": `<p class="bold-font">Ваш прогресс опережает 80% пользователей&nbsp;&mdash; вы&nbsp;уже освоили треть знаний и&nbsp;навыков, которые нужны для понимания основ бухгалтерии!</p>
				<p>Если вы&nbsp;хотите выйти на&nbsp;фриланс, получить повышение или взять под контроль бухгалтерию своего бизнеса, пройдите <span class="bold-font">курс &laquo;Бухгалтер&raquo;</span>, чтобы закрепить навыки работы в&nbsp;1С на&nbsp;практике и&nbsp;разобраться в&nbsp;бухгалтерском учёте.</p>
				<p class="bold-font">За&nbsp;3&nbsp;месяца вы:</p>
				<ul>
					<li>освоите программу 1С 8.3 и&nbsp;ЗУП на&nbsp;<nobr>онлайн-тренажёре</li>
					<li>получите актуальную теоретическую базу бухгалтера в&nbsp;2024 году;</li>
					<li>сможете работать в&nbsp;Excel и&nbsp;<nobr>Google-таблицах</nobr> на&nbsp;продвинутом уровне;</li>
					<li>скачаете 40+ бесплатных шаблонов, которые упростят вашу работу;</li>
					<li>научитесь управлять стрессом и&nbsp;выстраивать work&life balance на&nbsp;удалёнке.</li>
				</ul>
				<p class="bold-font">Подготовили для вас особые условия &#128071;</p>`
	},
	{
		"url": "academy-1c",
		"course": "Курс «Бухгалтер»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Если вы&nbsp;хотите выйти на&nbsp;фриланс, получить повышение или взять под контроль бухгалтерию своего бизнеса, пройдите <span class="bold-font">курс &laquo;Бухгалтер&raquo;</span>, чтобы закрепить навыки работы в&nbsp;1С на&nbsp;практике и&nbsp;разобраться в&nbsp;бухгалтерском учёте.</p>
				<p class="bold-font">За&nbsp;3&nbsp;месяца вы:</p>
				<ul>
					<li>освоите программу 1С 8.3 и&nbsp;ЗУП на&nbsp;<nobr>онлайн-тренажёре</nobr></li>
					<li>получите актуальную теоретическую базу бухгалтера в&nbsp;2024 году;</li>
					<li>сможете работать в&nbsp;Excel и&nbsp;<nobr>Google-таблицах</nobr> на&nbsp;продвинутом уровне;</li>
					<li>скачаете 40+ бесплатных шаблонов, которые упростят вашу работу;</li>
					<li>научитесь управлять стрессом и&nbsp;выстраивать work&life balance на&nbsp;удалёнке.</li>
				</ul>
				<p class="bold-font">Для прилежных студентов мы&nbsp;приготовили специальные условия&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-accountant",
		"course": "Курс «Главный бухгалтер»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Если вы&nbsp;хотите увеличить свой доход на&nbsp;текущем месте или найти новую работу с&nbsp;повышением, пройдите <span class="bold-font">курс &laquo;Главный бухгалтер&raquo;</span>, чтобы заранее подготовиться к&nbsp;карьерному росту.</p>
				<p class="bold-font">За&nbsp;3&nbsp;месяца вы:</p>
				<ul>
					<li>сможете взять под свой контроль все денежные потоки бизнеса;</li>
					<li>освоите финансовый анализ и&nbsp;управление командой;</li>
					<li>получите актуальную теоретическую базу бухгалтера в&nbsp;2024 году;</li>
					<li>научитесь работать в&nbsp;Excel и&nbsp;<nobr>Google-таблицах</nobr> на&nbsp;продвинутом уровне;</li>
					<li>поймёте, как управлять стрессом и&nbsp;выстраивать work&life balance на&nbsp;удалёнке.</li>
				</ul>
				<p class="bold-font">Для прилежных студентов мы&nbsp;приготовили специальные условия&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-basics-of-excel",
		"course": "Курс «Excel и Google-таблицы PRO»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Если вы&nbsp;хотите получить повышение на&nbsp;текущем месте или новые карьерные предложения с&nbsp;ростом в&nbsp;доходе, пройдите <span class="bold-font">курс &laquo;Excel и&nbsp;<nobr>Google-таблицы</nobr> PRO&raquo;</span>, чтобы усилить свою экспертизу в&nbsp;работе с&nbsp;таблицами.</p>
				<p class="bold-font">За&nbsp;3 недели вы:</p>
				<ul>
					<li>освоите современный аналог Excel&nbsp;&mdash; Google Sheets;</li>
					<li>научитесь работать в&nbsp;Excel на&nbsp;продвинутом уровне;</li>
					<li>поймёте, как редактировать таблицы в&nbsp;<nobr>онлайн-режиме</nobr> вместе с&nbsp;коллегами;</li>
					<li>научитесь визуализировать данные и&nbsp;убедительно доносить свою точку зрения с&nbsp;опорой на&nbsp;цифры.</li>
				</ul>
				<p class="bold-font">Для прилежных студентов мы&nbsp;приготовили специальные условия&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-excel",
		"course": "Курс «Excel и Google-таблицы Мастер»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Если вы&nbsp;хотите получить повышение на&nbsp;текущем месте или новые карьерные предложения с&nbsp;ростом в&nbsp;доходе, пройдите <span class="bold-font">курс &laquo;Excel и&nbsp;<nobr>Google-таблицы</nobr> Мастер&raquo;</span>, чтобы усилить свою экспертизу в&nbsp;работе с&nbsp;таблицами.</p>
				<p class="bold-font">За&nbsp;3 недели вы:</p>
				<ul>
					<li>освоите Excel и&nbsp;Google Sheets на&nbsp;продвинутом уровне;</li>
					<li>сможете автоматизировать свою работу с&nbsp;помощью макросов;</li>
					<li>поймёте, как редактировать расчёты в&nbsp;<nobr>онлайн-режиме</nobr> вместе с&nbsp;коллегами;</li>
					<li>научитесь строить интерактивные дашборды и&nbsp;убедительно доносить свою точку зрения с&nbsp;опорой на&nbsp;данные.</li>
				</ul>
				<p class="bold-font">Для прилежных студентов мы&nbsp;приготовили специальные условия&nbsp;&mdash; вам понравится &#128071;</p>`
	},
	{
		"url": "academy-excel-pro",
		"course": "Курс «Excel + Power BI»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Если вы&nbsp;хотите получить повышение на&nbsp;текущем месте или новые карьерные предложения с&nbsp;ростом в&nbsp;доходе, пройдите <span class="bold-font">курс <nobr>&laquo;Excel + Power BI&raquo;</nobr></span>, чтобы усилить свою экспертизу в&nbsp;аналитике данных.</p>
				<p class="bold-font">За&nbsp;2&nbsp;месяца вы:</p>
				<ul>
					<li>научитесь строить интерактивные дашборды в&nbsp;Power BI;</li>
					<li>сможете анализировать все ключевые метрики компании;</li>
					<li>освоите Excel и&nbsp;Google Sheets на&nbsp;продвинутом уровне;</li>
					<li>автоматизируете свою работу с&nbsp;помощью макросов.</li>
				</ul>
				<p class="bold-font">Для прилежных студентов мы&nbsp;приготовили специальные условия&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-excel-pro-only",
		"course": "Курс «Excel и Google-таблицы Мастер»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Если вы&nbsp;хотите получить повышение на&nbsp;текущем месте или новые карьерные предложения с&nbsp;ростом в&nbsp;доходе, пройдите <span class="bold-font">курс &laquo;Excel и&nbsp;<nobr>Google-таблицы</nobr> Мастер&raquo;</span>, чтобы усилить свою экспертизу в&nbsp;работе с&nbsp;таблицами.</p>
				<p class="bold-font">За&nbsp;3 недели вы:</li>
				<ul>
					<li>освоите Excel и&nbsp;Google Sheets на&nbsp;продвинутом уровне;</li>
					<li>сможете автоматизировать свою работу с&nbsp;помощью макросов;</li>
					<li>поймёте, как редактировать расчёты в&nbsp;<nobr>онлайн-режиме</nobr> вместе с&nbsp;коллегами;</li>
					<li>научитесь строить интерактивные дашборды и&nbsp;убедительно доносить свою точку зрения с&nbsp;опорой на&nbsp;данные.</li>
				</ul>
				<p class="bold-font">Для прилежных студентов мы&nbsp;приготовили специальные условия&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-excel-buhgalter",
		"course": "Курс «Excel и Google-таблицы PRO для управления финансами»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Если вы&nbsp;хотите получить повышение на&nbsp;текущем месте или перейти в&nbsp;сферу финансов, пройдите <span class="bold-font">курс &laquo;Excel и&nbsp;<nobr>Google-таблицы</nobr> PRO для управления финансами&raquo;</span>, чтобы усилить свою экспертизу в&nbsp;работе с&nbsp;аналитикой. В&nbsp;этом курсе будут полезные шаблоны, которые ускорят вашу работу.</p>
				<p class="bold-font">За&nbsp;3 недели вы:</p>
				<ul>
					<li>освоите Excel и&nbsp;Google Sheets на&nbsp;продвинутом уровне;</li>
					<li>научитесь управлять финансовыми показателями компании;</li>
					<li>поймёте, как редактировать расчёты в&nbsp;<nobr>онлайн-режиме</nobr> вместе с&nbsp;коллегами.</li>
				</ul>
				<p class="bold-font">Для прилежных студентов мы&nbsp;приготовили специальные условия&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "excel-finances",
		"course": "Курс «Power BI»",
		"progress": 30,
		"text": `<p class="bold-font">Вы&nbsp;прошли 30% обучения&nbsp;&mdash; так держать!</p>
				<p>Мы&nbsp;подготовили для вас секретное предложение: сделайте обучение ещё полезнее с&nbsp;<span class="bold-font">курсом &laquo;Power BI&raquo;</span>. За&nbsp;5&ndash;6 недель вы&nbsp;научитесь работать в&nbsp;одном из&nbsp;самых популярных инструментов для аналитики данных&nbsp;&mdash; Power BI.</p>
				<p class="bold-font">Excel используют для глубокого анализа данных и&nbsp;предварительных расчётов, а&nbsp;вместе с&nbsp;Power BI&nbsp;можно:</p>
				<ul>
					<li>визуализировать большие данные и&nbsp;строить понятные интерактивные дашборды;</li>
					<li>автоматизировать отчёты;</li>
					<li>давать доступ к&nbsp;отчётам и&nbsp;редактировать их&nbsp;совместно.</li>
				</ul>
				<p>Хотите быстрее справляться с&nbsp;рабочими задачами? <span class="bold-font">Мы&nbsp;подготовили для вас специальные условия &#128071;</span></p>`
	},
	{
		"url": "academy-hr-director",
		"course": "Программа «MBA: Управление персоналом»",
		"progress": 30,
		"text": `<p class="bold-font">Вы&nbsp;прошли 30% обучения&nbsp;&mdash; так держать!</p>
				<p>Мы&nbsp;подготовили для вас секретное предложение по&nbsp;апгрейду курса! Перейдите на&nbsp;продвинутую <span class="bold-font">программу &laquo;MBA: Управление персоналом&raquo;</span>, чтобы закрыть пробелы в&nbsp;стратегическом менеджменте и&nbsp;управлении командой.</p>
				<p class="bold-font">На&nbsp;ней вы:</p>
				<ul>
					<li>за&nbsp;6&nbsp;месяцев систематизируете знания и&nbsp;сфокусируетесь на&nbsp;задачах бизнеса;</li>
					<li>сможете оптимизировать <nobr>HR-процессы</nobr> в&nbsp;компании;</li>
					<li>пройдёте расширенные блоки менеджменту и&nbsp;стратегии, управлению проектами, <nobr>софт-скилам</nobr>, лидерству и&nbsp;коммуникации, а&nbsp;также по&nbsp;трудовому праву;</li>
					<li>получите диплом о&nbsp;профессиональной переподготовке.</li>
				</ul>
				<p>Готовы сделать мощный апгрейд своих навыков? <br><span class="bold-font">Мы&nbsp;подготовили для вас специальные условия &#128071;</span></p>`
	},
	{
		"url": "academy-hr-business-partner",
		"course": "Программа «Директор по управлению персоналом»",
		"progress": 30,
		"text": `<p class="bold-font">Вы&nbsp;прошли 30% обучения&nbsp;&mdash; так держать!</p>
				<p>Мы&nbsp;подготовили для вас секретное предложение по&nbsp;апгрейду курса! Пройдите продвинутую <span class="bold-font">программу &laquo;Директор по&nbsp;управлению персоналом&raquo;</span>, чтобы перейти на&nbsp;управленческую позицию и&nbsp;сфокусироваться на&nbsp;задачах бизнеса.</p>
				<p class="bold-font">На&nbsp;курсе вы:</p>
				<ul>
					<li>освоите все навыки для работы в&nbsp;<nobr>топ-менеджменте</nobr> на&nbsp;уроках от&nbsp;лидеров индустрии, 45+ <nobr>бизнес-кейсах</nobr>, тренажёрах и&nbsp;интервью;</li>
					<li>пройдёте усиленные блоки по&nbsp;обучению и&nbsp;развитию в&nbsp;корпоративной среде, оценке эффективности обучения, <nobr>софт-скилам</nobr> руководителя, а&nbsp;также&nbsp;&mdash; по&nbsp;организации удалённой работы в&nbsp;компании и&nbsp;по&nbsp;правовому регулированию труда;</li>
					<li>получите шаблоны и&nbsp;&laquo;шпаргалки&raquo; для работы от&nbsp;спикеров, а&nbsp;в&nbsp;конце обучения&nbsp;&mdash; удостоверение о&nbsp;повышении квалификации.</li>
				</ul>
				<p class="bold-font">Специальные условия для вас &#128071;</p>`
	},
	{
		"url": "academy-hr-manager",
		"course": "Продвинутая программа «HR бизнес-партнёр»",
		"progress": 30,
		"text": `<p class="bold-font">Вы&nbsp;прошли 30% обучения&nbsp;&mdash; так держать!</p>
				<p>Мы&nbsp;подготовили для вас секретное предложение по&nbsp;апгрейду курса! Перейдите на&nbsp;<span class="bold-font">продвинутую программу &laquo;HR&nbsp;<nobr>бизнес-партнёр</nobr>&raquo;</span>&nbsp;&mdash; для тех, кто хочет выстраивать <nobr>HR-стратегию</nobr>, управлять изменениями и&nbsp;<nobr>HR-брендом</nobr> компании.</p>
				<p class="bold-font">На&nbsp;ней вы:</p>
				<ul>
					<li>научитесь увеличивать эффективность сотрудников и&nbsp;доносить идеи <nobr>топ-менеджменту</nobr> и&nbsp;всему коллективу;</li>
					<li>пройдёте блоки по&nbsp;финансовой грамотности, стратегическому и&nbsp;проектному <nobr>HR-менеджменту</nobr>, в&nbsp;том числе посмотрите лекции зарубежных преподавателей, например, доцента по&nbsp;управлению и&nbsp;<nobr>HR-менеджменту</nobr> в&nbsp;HEC Paris;</li>
					<li>получите доступ к&nbsp;курсу по&nbsp;Excel.</li>
				</ul>
				<p class="bold-font">Сделайте мощный карьерный апгрейд&nbsp;&mdash; по&nbsp;специальным условиям для вас &#128071;</p>`
	},
	{
		"url": "academy-online-course-creation",
		"course": "Продвинутая программа «Руководитель образовательных проектов»",
		"progress": 30,
		"text": `<p class="bold-font">Вы&nbsp;прошли 30% обучения&nbsp;&mdash; так держать!</p>
				<p>Мы&nbsp;подготовили для вас секретное предложение по&nbsp;апгрейду курса! Перейдите на&nbsp;<span class="bold-font">продвинутую программу &laquo;Руководитель образовательных проектов&raquo;</span> и&nbsp;научитесь системному подходу к&nbsp;созданию курсов и&nbsp;масштабированию бизнеса.</p>
				<p class="bold-font">На&nbsp;ней вы&nbsp;станете стратегом, методистом и&nbsp;менеджером и&nbsp;научитесь:</p>
				<ul>
					<li>создавать и&nbsp;продвигать образовательные проекты;</li>
					<li>глубоко погружаться в&nbsp;исследование целевой аудитории и&nbsp;её запросов;</li>
					<li>управлять проектами и&nbsp;командами с&nbsp;помощью <nobr>Agile-подхода</nobr>;</li>
					<li>считать экономику проекта;</li>
					<li>координировать <nobr>бизнес-процессы</nobr>, продажи и&nbsp;маркетинг.</li>
				</ul>
				<p class="bold-font"><span class="bold-font">Мы&nbsp;подготовили для вас специальные условия.</span> В&nbsp;стоимость включена личная консультация по&nbsp;курсу и&nbsp;диплому &#128071;</p>`
	},
	{
		"url": "academy-methodist",
		"course": "Продвинутая программа «Методист: тариф Специалист»",
		"progress": 30,
		"text": `<p class="bold-font">Вы&nbsp;прошли 30% обучения&nbsp;&mdash; так держать!</p>
				<p>За&nbsp;это мы&nbsp;подготовили секретное предложение по&nbsp;апгрейду вашего курса! Перейдите на&nbsp;<span class="bold-font">продвинутую программу &laquo;Методист: тариф Специалист&raquo;</span> и&nbsp;получите расширенные знания и&nbsp;эффективные инструменты для карьерного роста.</p>
				<p class="bold-font">За&nbsp;4&nbsp;месяца вы:</p>
				<ul>
					<li>изучите всё, что есть в&nbsp;вашем базовом тарифе;</li>
					<li>узнаете принципы педагогической модели, которую используют западные коллеги&nbsp;&mdash; 4С/ID;</li>
					<li>научитесь методикам исследования аудитории, визуализации информации управления своим временем и&nbsp;оптимизации работы с&nbsp;помощью нейросетей;</li>
					<li>напишете дипломную работу по&nbsp;своему проекту.</li>
				</ul>
				<p>Сделайте апгрейд курса и&nbsp;своей карьеры! <span class="bold-font">Мы&nbsp;подготовили для вас специальные условия &#128071;</span></p>`
	},
	{
		"url": "academy-methodist-specialist",
		"course": "Апгрейд вашего курса до PRO-тарифа",
		"progress": 30,
		"text": `<p class="bold-font">Вы&nbsp;прошли 30% обучения&nbsp;&mdash; так держать!</p>
				<p>За&nbsp;это мы&nbsp;подготовили секретное предложение по&nbsp;<span class="bold-font">апгрейду вашего курса до&nbsp;<nobr>PRO-тарифа</nobr></span>! Получите продвинутые инструменты для работы и&nbsp;повысьте свою ценность на&nbsp;рынке.</p>
				<p class="bold-font">За&nbsp;6&nbsp;месяцев вы:</p>
				<ul>
					<li>изучите всё, что есть в&nbsp;вашем тарифе;</li>
					<li>научитесь использовать метрики оценки курса, работать с&nbsp;Figma, разбираться в&nbsp;процессах в&nbsp;<nobr>онлайн-школах</nobr> и&nbsp;работе инфобизнеса, использовать нейросети и&nbsp;инструменты <nobr>тайм-менеджмента</nobr>;</li>
					<li>поймёте, как выстраивать личный бренд методиста и&nbsp;работать с&nbsp;частными экспертами и&nbsp;блогерами;</li>
					<li>напишете дипломную работу по&nbsp;своему проекту.</li>
				</ul>
				<p><span class="bold-font">Мы&nbsp;подготовили для вас специальные условия.</span> В&nbsp;стоимость включены 2 личных консультации по&nbsp;курсу и&nbsp;диплому &#128071;</p>`
	},
	{
		"url": "academy-tnd-manager",
		"course": "Продвинутый курс «Директор по обучению персонала»",
		"progress": 30,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 30% курса &laquo;Менеджер по&nbsp;обучению персонала&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;<span class="bold-font">продвинутый курс &laquo;Директор по&nbsp;обучению персонала&raquo;</span>.</p>
				<p class="bold-font">На&nbsp;нём вы:</p>
				<ul>
					<li>освоите стратегический <nobr>HR-менеджмент</nobr> у&nbsp;доцента HEC Paris, одной из&nbsp;<nobr>3-х</nobr> лучших европейских <nobr>бизнес-школ</nobr>;</li>
					<li>обучитесь эффективному менеджменту по&nbsp;лекциям Ицхака Адизеса&nbsp;&mdash; мирового специалиста по&nbsp;управлению, лектора Стэнфорда и&nbsp;Колумбийского университета;</li>
					<li>автоматизируете <nobr>HR-процессы</nobr> с&nbsp;помощью нейросетей;</li>
					<li>прокачаете soft skills и&nbsp;эмоциональный интеллект руководителя и&nbsp;сможете претендовать на&nbsp;повышение.</li>
				</ul>
				<p>Готовы сделать мощный рывок в&nbsp;своей карьере? <span class="bold-font">Нажмите кнопку ниже и&nbsp;начните путь в&nbsp;высшую лигу <nobr>HR-директоров</nobr></span>.</p>`
	},
	{
		"url": "academy-psychology",
		"course": "Продвинутый курс «Психолог: Base»",
		"progress": 30,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 30% курса &laquo;Как справиться с&nbsp;тревогой&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;<span class="bold-font">продвинутый курс &laquo;Психолог: Base&raquo;</span>.</p>
				<p class="bold-font">На&nbsp;нём вы:</p>
				<ul>
					<li>освоите профессиональные методики самопомощи и&nbsp;научитесь справляться с&nbsp;тревожностью, гневом и&nbsp;паническими атаками;</li>
					<li>изучите современные подходы к&nbsp;выстраиванию здоровых отношений с&nbsp;близкими, включая специальный блок по&nbsp;детской психологии;</li>
					<li>узнаете, как устроена работа психолога, и&nbsp;сделаете первые шаги к&nbsp;карьере.</li>
				</ul>
				<p class="bold-font">Нажмите кнопку ниже, чтобы помогать себе и&nbsp;близким жить качественно.</p>`
	},
	{
		"url": "academy-project-manager-basic",
		"course": "Продвинутый курс «Менеджер проектов. Оптимальный».",
		"progress": 30,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 30% курса &laquo;Менеджер проектов. Базовый&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;<span class="bold-font">продвинутый курс &laquo;Менеджер проектов. Оптимальный&raquo;</span>.</p>
				<p class="bold-font">На&nbsp;нём вы:</p>
				<ul>
					<li>за&nbsp;5&nbsp;месяцев изучите проектное управление и&nbsp;научитесь работать со&nbsp;сложными практическими кейсами;</li>
					<li>получите 3 личные консультации с&nbsp;практикующими менеджерами проектов, узнаете частые ошибки начинающих специалистов и&nbsp;построите свой трек развития;</li>
					<li>улучшите резюме и&nbsp;портфолио и&nbsp;получите содействие трудоустройству: если не&nbsp;найдёте работу&nbsp;&mdash; мы&nbsp;вернём деньги;</li>
					<li>освоите дополнительные курсы по&nbsp;развитию soft skills: научитесь выступать без страха, заключать выгодные для вас договоры, быть наставником для подчинённых&nbsp;&mdash; и&nbsp;всё успевать.</li>
				</ul>
				<p class="bold-font">Нажмите кнопку ниже и&nbsp;сделайте мощный рывок в&nbsp;своей карьере!</p>`
	},
	{
		"url": "academy-project-manager",
		"course": "Продвинутый курс «Менеджер проектов. Экспертный»",
		"progress": 30,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 30% курса &laquo;Менеджер проектов. Оптимальный&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;<span class="bold-font">продвинутый курс &laquo;Менеджер проектов. Экспертный&raquo;</span>: в&nbsp;финале вы&nbsp;получите диплом о&nbsp;профессиональной переподготовке установленного образца.</p>
				<p class="bold-font">На&nbsp;нём вы:</p>
				<ul>
					<li>за&nbsp;7&nbsp;месяцев изучите специфику управления проектами в&nbsp;IT&nbsp;и&nbsp;digital, финансах и&nbsp;аналитике, и&nbsp;станете востребованным специалистом в&nbsp;высокотехнологичных отраслях;</li>
					<li>получите 5 персональных менторских консультаций с&nbsp;руководителями проектов и&nbsp;разработаете персональный трек развития для решения ваших профессиональных задач;</li>
					<li>освоите программу по&nbsp;лидерству и&nbsp;управлению командой, научитесь эффективно вести масштабные проекты.</li>
				</ul>
				<p class="bold-font">Нажмите кнопку ниже и&nbsp;сделайте мощный рывок в&nbsp;своей карьере!</p>`
	},
	{
		"url": "academy-marketplace-manager",
		"course": "Продвинутый курс «PRO-менеджер по работе с маркетплейсами»",
		"progress": 30,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 30% курса &laquo;Менеджер по&nbsp;работе с&nbsp;маркетплейсами&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;<span class="bold-font">продвинутый курс &laquo;<nobr>PRO-менеджер</nobr> по&nbsp;работе с&nbsp;маркетплейсами&raquo;</span>.</p>
				<p class="bold-font">На&nbsp;нём вы:</p>
				<ul>
					<li>за&nbsp;3,5&nbsp;месяца получите углублённые знания для быстрого старта карьеры и&nbsp;сразу станете специалистом уровня PRO;</li>
					<li>выполните 9 практических заданий и&nbsp;будете чётко понимать, как решать рабочие задачи;</li>
					<li>улучшите резюме и&nbsp;портфолио, и, если не&nbsp;найдёте работу,&nbsp;&mdash; получите деньги за&nbsp;обучение;</li>
					<li>пройдёте дополнительный модуль &laquo;Дизайнер карточек на&nbsp;маркетплейсах&raquo; и&nbsp;сможете влиять на&nbsp;продажи с&nbsp;помощью карточек товаров.</li>
				</ul>
				<p class="bold-font">Нажмите кнопку ниже и&nbsp;сделайте мощный рывок в&nbsp;своей карьере!</p>`
	},
	{
		"url": "academy-marketplace-manager-pro",
		"course": "Курс «PRO-селлер на маркетплейсах»",
		"progress": 30,
		"text": `<p class="bold-font">Ваш прогресс опережает 80% пользователей&nbsp;&mdash; вы&nbsp;уже освоили треть знаний и&nbsp;навыков, которые нужны менеджеру маркетплейсов!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курс &laquo;<nobr>PRO-селлер</nobr> на&nbsp;маркетплейсах&raquo;</span>, чтобы вы&nbsp;ещё глубже погрузились в&nbsp;тему.</p>
				<p>Если вы&nbsp;хотите не&nbsp;только работать на&nbsp;маркетплейсах как наёмный сотрудник, но&nbsp;и&nbsp;запустить там своё дело, то&nbsp;эта программа будет полезнее: в&nbsp;ней два раза больше практики, модули по&nbsp;управлению продажами и&nbsp;масштабированию бизнеса на&nbsp;платформах. Бонусом&nbsp;&mdash; 5 консультаций с&nbsp;экспертами.</p>
				<p class="bold-font">Подготовили для вас особые условия &#128071;</p>`
	},
	{
		"url": "academy-smm",
		"course": "Курс «SMM-специалист»",
		"progress": 30,
		"text": `<p class="bold-font">Ваш прогресс опережает 80% пользователей&nbsp;&mdash; вы&nbsp;уже освоили треть знаний и&nbsp;навыков, которые нужны <nobr>SMM-менеджеру</nobr>!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курс <nobr>&laquo;SMM-специалист&raquo;</nobr></span>, чтобы глубже погрузиться в&nbsp;тему. Вас ждут дополнительные 30 часов практики, лекции по&nbsp;брендингу, аналитике, инфлюенс- и&nbsp;<nobr>видео-маркетингу</nobr>, а&nbsp;также полноценный дипломный проект, который вы&nbsp;добавите в&nbsp;портфолио.</p>
				<p class="bold-font">Подготовили для вас особые условия &#128071;</p>`
	},
	{
		"url": "academy-business-smm",
		"course": "Курс «Интернет-маркетолог»",
		"progress": 30,
		"text": `<p class="bold-font">Ваш прогресс опережает 80% пользователей&nbsp;&mdash; вы&nbsp;уже освоили треть знаний и&nbsp;навыков, которые нужны для работы в&nbsp;социальных сетях!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курс <nobr>&laquo;Интернет-маркетолог&raquo;</nobr></span>, чтобы глубже погрузиться в&nbsp;тему маркетинга и&nbsp;комплексно проработать продвижение своего бизнеса.</p>
				<p class="bold-font">Вы&nbsp;изучите:</p>
				<ul>
					<li>запуск таргетированной и&nbsp;контекстной рекламы;</li>
					<li>расширенную аналитику и&nbsp;оптимизацию рекламных кампаний;</li>
					<li><nobr>SEO-оптимизацию</nobr>;</li>
					<li><nobr>email-маркетинг</nobr> и&nbsp;автоворонки;</li>
				</ul>
				<p>Также вы&nbsp;получите бонусные модули по&nbsp;<nobr>тайм-менеджменту</nobr> и&nbsp;проведению переговоров и&nbsp;презентаций.</p>
				<p class="bold-font">Подготовили для вас особые условия &#128071;</p>`
	},
	{
		"url": "academy-internet-marketing",
		"course": "Курс «Директор по маркетингу»",
		"progress": 30,
		"text": `<p class="bold-font">Ваш прогресс опережает 80% пользователей&nbsp;&mdash; вы&nbsp;уже освоили треть знаний и&nbsp;навыков, которые нужны <nobr>интернет-маркетологу</nobr> для работы!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курс &laquo;Директор по&nbsp;маркетингу&raquo;</span>, чтобы глубже погрузиться в&nbsp;тему продвижения бизнеса и&nbsp;занять высокую должность&nbsp;&mdash; и,&nbsp;следовательно, больше зарабатывать.</p>
				<p class="bold-font">Вам будут доступны дополнительные модули:</p>
				<ul>
					<li>менеджмент и&nbsp;стратегия;</li>
					<li>управление продуктом, продажами и&nbsp;проектами;</li>
					<li>координация команды и&nbsp;развитие лидерских навыков;</li>
				</ul>
				<p>Также вы&nbsp;получите 50+ бонусных материалов и&nbsp;<nobr>чек-листов</nobr>, которые можно быстро интегрировать в&nbsp;свой бизнес или рабочие проекты.</p>
				<p class="bold-font">Подготовили для вас особые условия &#128071;</p>`
	},
	{
		"url": "academy-marketing-director",
		"course": "Курс «MBA: Управление маркетингом»",
		"progress": 30,
		"text": `<p class="bold-font">Ваш прогресс опережает 80% пользователей&nbsp;&mdash; вы&nbsp;уже освоили треть знаний и&nbsp;навыков, которые нужны директору по&nbsp;маркетингу для работы!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курс &laquo;MBA: Управление маркетингом&raquo;</span>, чтобы глубже погрузиться в&nbsp;тему продвижения бизнеса и&nbsp;занять высокую должность&nbsp;&mdash; и, следовательно, больше зарабатывать.</p>
				<p class="bold-font">Вам будут доступны дополнительные модули:</p>
				<ul>
					<li>менеджмент и&nbsp;стратегия;</li>
					<li>управление продуктом, продажами и&nbsp;проектами;</li>
					<li>координация команды и&nbsp;развитие лидерских навыков;</li>
				</ul>
				<p>Также вы&nbsp;получите 50+ бонусных материалов и&nbsp;<nobr>чек-листов</nobr>, которые можно быстро интегрировать в&nbsp;свой бизнес или рабочие проекты.</p>
				<p class="bold-font">Подготовили для вас особые условия &#128071;</p>`
	},
	{
		"url": "academy-web-design",
		"course": "Курс «Профессия: веб-дизайнер»",
		"progress": 30,
		"text": `<p class="bold-font">Ваш прогресс опережает 80% пользователей&nbsp;&mdash; вы&nbsp;уже освоили треть знаний и&nbsp;навыков, которые нужны дизайнеру для старта!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курс &laquo;Профессия: <nobr>веб-дизайнер</nobr>&raquo;</span>, чтобы полноценно освоить навыки и&nbsp;уверенно работать с&nbsp;крупными клиентами.</p>
				<p>Вы&nbsp;научитесь работать в&nbsp;Figma, Adobe Photoshop, Illustrator, InDesign и&nbsp;Tilda, изучите <nobr>UX-дизайн</nobr> и&nbsp;даже сможете верстать с&nbsp;помощью <acronym title="HyperText Markup Language" lang="en">HTML</acronym> и&nbsp;<acronym title="Cascading Style Sheets" lang="en">CSS</acronym>.</p>
				<p class="bold-font">Подготовили для вас особые условия &#128071;</p>`
	},
	{
		"url": "academy-revit-autodesk",
		"course": "Курс «Дизайнер интерьеров»",
		"progress": 30,
		"text": `<p class="bold-font">Ваш прогресс опережает 80% пользователей&nbsp;&mdash; вы&nbsp;уже освоили треть навыков по&nbsp;работе с&nbsp;Revit!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курс &laquo;Дизайнер интерьеров&raquo;</span>. В&nbsp;нём на&nbsp;100+ часов больше контента: основы дизайна интерьеров, скринкасты и&nbsp;тренажёры по&nbsp;ArchiCAD, 3D Max и&nbsp;Photoshop.</p>
				<p>Вы&nbsp;освоите полноценную профессию, все современные тренды и&nbsp;инструменты, необходимые для работы в&nbsp;этой сфере, научитесь коммуницировать с&nbsp;заказчиками и&nbsp;вести проекты.</p>
				<p class="bold-font">Подготовили для вас особые условия &#128071;</p>`
	},
	{
		"url": "academy-fin-analyst",
		"course": "Программа «Финансовый директор»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Перейдите на&nbsp;<span class="bold-font">программу &laquo;Финансовый директор&raquo;</span> на&nbsp;выгодных условиях, чтобы занять стратегическую роль и&nbsp;взять под свой контроль все денежные потоки бизнеса.</p>
				<p class="bold-font">Бонусом к&nbsp;текущему курсу вы:</p>
				<ul>
					<li>разберётесь в&nbsp;налоговых режимах, управлении корпоративными финансами, рисками и&nbsp;стоимостью компании;</li>
					<li>пройдёте расширенный блок по&nbsp;анализу отчётности и&nbsp;МСФО;</li>
					<li>освоите финансовое планирование и&nbsp;бюджетирование, бухгалтерский и&nbsp;налоговый учёт;</li>
					<li>подготовитесь к&nbsp;публичным выступлениям, прокачаете лидерские качества и&nbsp;<nobr>тайм-менеджмент</nobr>;</li>
					<li>получите диплом о&nbsp;профессиональной переподготовке вместо удостоверения о&nbsp;повышении квалификации.</li>
				</ul>
				<p class="bold-font">Зарабатывайте больше после продвинутого курса на&nbsp;специальных условиях&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-chief-of-operations",
		"course": "Программа «МВА Эксперт: Операционное управление»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Перейдите на&nbsp;<span class="bold-font">программу &laquo;МВА Эксперт: Операционное управление&raquo;</span> на&nbsp;выгодных условиях, чтобы углубить свои знания в&nbsp;операционном менеджменте и&nbsp;разобрать проблемы вашего бизнеса на&nbsp;2 личных встречах с&nbsp;экспертами.</p>
				<p class="bold-font">Бонусом к&nbsp;текущему курсу вы:</p>
				<ul>
					<li>пройдёте расширенный блок по&nbsp;стратегическому менеджменту;</li>
					<li>освоите <nobr>бизнес-моделирование</nobr> для разных типов компаний;</li>
					<li>научитесь формировать и&nbsp;реализовывать стратегию маркетинга, продаж и&nbsp;HR;</li>
					<li>разберётесь в&nbsp;экономическом анализе;</li>
					<li>прокачаете лидерские качества и&nbsp;сможете грамотно руководить командой.</li>
				</ul>
				<p class="bold-font">Принимайте обоснованные управленческие решения после продвинутого курса на&nbsp;специальных условиях&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-fin-officer",
		"course": "Программа «МВА Эксперт: Управление финансами»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Перейдите на&nbsp;<span class="bold-font">программу &laquo;МВА Эксперт: Управление финансами&raquo;</span> на&nbsp;выгодных условиях, чтобы укрепить свои управленческие навыки, вырасти в&nbsp;доходе и&nbsp;повысить прибыльность компании.</p>
				<p class="bold-font">Бонусом к&nbsp;текущему курсу вы:</p>
				<ul>
					<li>пройдёте расширенный блок по&nbsp;стратегическому менеджменту;</li>
					<li>узнаете, как организовывать работу отдела продаж, маркетинга и&nbsp;HR;</li>
					<li>научитесь анализировать ключевые метрики и&nbsp;составлять <nobr>бизнес-модели</nobr> для инвесторов;</li>
					<li>сможете быстрее решать ежедневные задачи в&nbsp;Excel и&nbsp;<nobr>Google-таблицах</nobr>;</li>
					<li>прокачаете лидерские качества, чтобы грамотно мотивировать команду.</li>
				</ul>
				<p class="bold-font">Получите престижный диплом MBA, верифицированный &laquo;Сколково&raquo;, на&nbsp;специальных условиях&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-director-of-strategy",
		"course": "Программа «ЕМВА: Стратегическое управление»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Перейдите на&nbsp;<span class="bold-font">программу &laquo;ЕМВА: Стратегическое управление&raquo;</span> на&nbsp;выгодных условиях, чтобы систематизировать свои знания в&nbsp;стратегическом менеджменте и&nbsp;разобрать проблемы вашего бизнеса на&nbsp;2 личных встречах с&nbsp;экспертами.</p>
				<p class="bold-font">Бонусом к&nbsp;текущему курсу вы:</p>
				<ul>
					<li>освоите <nobr>бизнес-моделирование</nobr> для разных типов компаний;</li>
					<li>узнаете, как выстроить финансовую, инвестиционную и&nbsp;продуктовую стратегии;</li>
					<li>научитесь анализировать рынок, управлять рисками и&nbsp;масштабировать компанию;</li>
					<li>прокачаете лидерские качества, чтобы умело вести переговоры и&nbsp;презентации;</li>
					<li>сможете перенять опыт известных управленцев России и&nbsp;мира через интервью.</li>
				</ul>
				<p class="bold-font">Мы&nbsp;подготовили специальные условия для вашего карьерного прорыва&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-director-economy-finance",
		"course": "Программа «МВА Эксперт: Управление предприятием»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Перейдите на&nbsp;<span class="bold-font">программу &laquo;МВА Эксперт: Управление предприятием&raquo;</span> на&nbsp;выгодных условиях, чтобы систематизировать свои знания в&nbsp;стратегическом менеджменте и&nbsp;повысить <nobr>бизнес-результаты</nobr> всей компании.</p>
				<p class="bold-font">Бонусом к&nbsp;текущему курсу вы:</p>
				<ul>
					<li>узнаете, как организовывать работу отдела финансов, логистики, продаж и&nbsp;HR;</li>
					<li>научитесь моделировать <nobr>бизнес-процессы</nobr>, управлять проектами и&nbsp;анализировать ключевые метрики;</li>
					<li>сможете составлять <nobr>бизнес-модели</nobr> для инвесторов и&nbsp;умело вести переговоры;</li>
					<li>прокачаете лидерские качества, чтобы грамотно мотивировать команду;</li>
					<li>сможете перенять опыт известных управленцев России и&nbsp;мира через интервью.</li>
				</ul>
				<p class="bold-font">Получите престижный диплом MBA, верифицированный &laquo;Сколково&raquo;, на&nbsp;специальных условиях&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-python-basics",
		"course": "Курс «Python-разработчик»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Перейдите на&nbsp;<span class="bold-font">курс <nobr>&laquo;Python-разработчик&raquo;</nobr></span>, чтобы полноценно освоить самый популярный язык программирования и&nbsp;стать <nobr>junior-специалистом</nobr> со&nbsp;средней зарплатой 106&nbsp;000&nbsp;рублей.</p>
				<p class="bold-font">За&nbsp;9&nbsp;месяцев обучения вы:</p>
				<ul>
					<li>решите 40+ интерактивных заданий в&nbsp;<nobr>онлайн-тренажёре</nobr>;</li>
					<li>освоите фреймворки Django и&nbsp;FastAPI, язык SQL, ОС&nbsp;Linux и&nbsp;базы данных;</li>
					<li>соберёте портфолио из&nbsp;10 проектов, чтобы быстрее найти работу;</li>
					<li>составите резюме, пройдёте карьерную консультацию и&nbsp;получите гарантию трудоустройства;</li>
					<li>получите диплом о&nbsp;профессиональной переподготовке и&nbsp;диплом Eduson, верифицированный &laquo;Сколково&raquo;.</li>
				</ul>
				<p class="bold-font">Обучитесь комплексной разработке на&nbsp;Python на&nbsp;специальных условиях&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-qa",
		"course": "Курс «Инженер по тестированию»",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Перейдите на&nbsp;<span class="bold-font">курс &laquo;Инженер по&nbsp;тестированию&raquo;</span>, чтобы эффективнее находить баги и&nbsp;претендовать на&nbsp;зарплату выше, чем у&nbsp;мануального тестировщика.</p>
				<p class="bold-font">За&nbsp;6&nbsp;месяцев обучения вы:</p>
				<ul>
					<li>освоите не&nbsp;только ручное, но&nbsp;и&nbsp;автоматизированное тестирование на&nbsp;Python;</li>
					<li>научитесь работать с&nbsp;базами данных, SQL и&nbsp;закрепите навыки на&nbsp;33 практических заданиях;</li>
					<li>добавите 21 проект в&nbsp;портфолио&nbsp;&mdash; на&nbsp;12 больше, чем в&nbsp;вашем текущем курсе;</li>
					<li>составите резюме, пройдёте карьерную консультацию и&nbsp;получите гарантию трудоустройства;</li>
					<li>получите преимущество на&nbsp;рынке труда и&nbsp;сможете вырасти в&nbsp;доходе.</li>
				</ul>
				<p class="bold-font">Шагните сразу на&nbsp;следующую ступень профессионального развития на&nbsp;специальных условиях&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-python-developer",
		"course": "Продвинутая программа «Fullstack-разработчик»",
		"progress": 30,
		"text": `<p class="bold-font">Вы&nbsp;прошли 30% обучения&nbsp;&mdash; так держать!</p>
				<p>Мы&nbsp;подготовили для вас секретное предложение по&nbsp;апгрейду курса! Перейдите на&nbsp;<span class="bold-font">продвинутую программу <nobr>&laquo;Fullstack-разработчик&raquo;</nobr></span>.</p>
				<p class="bold-font">Программа на&nbsp;18 академических часов больше вашей&nbsp;&mdash; вы&nbsp;освоите не&nbsp;только <nobr>бэкенд-разработку</nobr> на&nbsp;Python, но&nbsp;и:</p>
				<ul>
					<li><nobr>фронтенд-разработку</nobr>&nbsp;&mdash; языки <acronym title="HyperText Markup Language" lang="en">HTML</acronym>, <acronym title="Cascading Style Sheets" lang="en">CSS</acronym>, JavaScript;</li>
					<li>тестирование <nobr>фронтенд-части</nobr>;</li>
					<li>основы UX/UI;</li>
					<li>работу с&nbsp;библиотекой React.</li>
				</ul>
				<p><nobr>Фулстек-разработчики</nobr> востребованы на&nbsp;рынке, так как совмещают в&nbsp;себе два направления&nbsp;&mdash; фронтенд и&nbsp;бэкенд. Сделайте апгрейд курса и&nbsp;своей зарплаты&nbsp;&mdash; <span class="bold-font">на&nbsp;специальных условиях для вас&#128071;</span></p>`
	},
	{
		"url": "academy-fullstack-developer",
		"course": "Апгрейд курса до PRO-тарифа",
		"progress": 30,
		"text": `<p class="bold-font">Вы&nbsp;прошли 30% обучения&nbsp;&mdash; так держать!</p>
				<p>Мы&nbsp;подготовили для вас секретное предложение по&nbsp;<span class="bold-font">апгрейду курса до&nbsp;<nobr>PRO-тарифа</nobr></span>! Обучитесь дополнительным навыкам&nbsp;&mdash; это повысит вашу ценность на&nbsp;рынке.</p>
				<p>Дополнительно к&nbsp;вашей программе вы&nbsp;сделаете больше практических проектов, например, сайт с&nbsp;рейтингом фильмов на&nbsp;React, и&nbsp;получите <nobr>код-ревью</nobr> на&nbsp;2 кейса.</p>
				<p class="bold-font">Также вы&nbsp;пройдёте модули:</p>
				<ul>
					<li>UX/<nobr>UI-дизайн</nobr>;</li>
					<li>работа с&nbsp;библиотекой React;</li>
					<li>алгоритмы и&nbsp;структуры данных;</li>
					<li>работа с&nbsp;Linux и&nbsp;удалёнными серверами;</li>
					<li>развёртывание приложения.</li>
				</ul>
				<p>Пополните ваше портфолио разработчика&nbsp;&mdash; <span class="bold-font">мы&nbsp;подготовили для вас специальные условия &#128071;</span></p>`
	},
	{
		"url": "academy-frontend-developer",
		"course": "Апгрейд курса до PRO-тарифа",
		"progress": 30,
		"text": `<p class="bold-font">Вы&nbsp;прошли 30% обучения&nbsp;&mdash; так держать!</p>
				<p>Мы&nbsp;подготовили для вас секретное предложение по&nbsp;<span class="bold-font">апгрейду курса до&nbsp;<nobr>PRO-тарифа</nobr></span>! Погрузитесь в&nbsp;<nobr>бэкенд-разработку</nobr> на&nbsp;Python, чтобы выделиться на&nbsp;рынке и&nbsp;эффективно работать в&nbsp;команде.</p>
				<p class="bold-font">Дополнительно к&nbsp;вашей программе вы&nbsp;пройдёте модули:</p>
				<ul>
					<li>разработка на&nbsp;Python;</li>
					<li>основы <nobr>объектно-ориентированного</nobr> программирования;</li>
					<li>подготовка к&nbsp;запуску проекта и&nbsp;продакшн;</li>
					<li>работа с&nbsp;базами данных и&nbsp;SQL на&nbsp;продвинутом уровне.</li>
				</ul>
				<p>В&nbsp;конце обучения вы&nbsp;сделаете 2 дипломных работы&nbsp;&mdash; по&nbsp;фронтенд- и&nbsp;<nobr>бэкенд-разработке</nobr>&nbsp;&mdash; и&nbsp;добавите их&nbsp;в&nbsp;портфолио. <span class="bold-font">Специальные условия для вас &#128071;</span></p>`
	},
	{
		"url": "academy-data-analyst",
		"course": "Апгрейд курса до PRO-тарифа",
		"progress": 15,
		"text": `<p class="bold-font">Вы&nbsp;прошли 15% обучения&nbsp;&mdash; так держать!</p>
				<p>Мы&nbsp;подготовили для вас секретное предложение по&nbsp;<span class="bold-font">апгрейду курса до&nbsp;<nobr>PRO-тарифа</nobr></span>!</p>
				<p class="bold-font">На&nbsp;нём вы&nbsp;дополнительно получите:</p>
				<ul>
					<li>6 бонусных курсов: по&nbsp;Agile, машинному обучению, разведочному анализу, линейной регрессии и&nbsp;классификаторам;</li>
					<li>помощь с&nbsp;трудоустройством и&nbsp;консультацию с&nbsp;<nobr>HR-специалистом</nobr> в&nbsp;сфере аналитики;</li>
					<li>3 консультации с&nbsp;практикующими экспертами из&nbsp;Datalatte, &laquo;Работа.ру&raquo; и&nbsp;SberDevices&nbsp;&mdash; для глубокого погружения в&nbsp;профессию.</li>
				</ul>
				<p>Хотите освоить продвинутые инструменты и&nbsp;уверенно стартовать в&nbsp;профессии аналитика данных? <span class="bold-font">Подготовили для вас специальные условия &#128071;</span></p>`
	},
	{
		"url": "academy-data-analyst-pro",
		"course": "Апгрейд курса до тарифа «Мастер»",
		"progress": 15,
		"text": `<p class="bold-font">Вы&nbsp;прошли 15% обучения&nbsp;&mdash; так держать!</p>
				<p>Мы&nbsp;подготовили для вас секретное предложение по&nbsp;<span class="bold-font">апгрейду курса до&nbsp;тарифа &laquo;Мастер&raquo;</span>! Получите дополнительные инструменты для работы аналитиком данных и&nbsp;навыки <nobr>бизнес-аналитика</nobr>.</p>
				<p class="bold-font">На&nbsp;нём вы&nbsp;дополнительно:</p>
				<ul>
					<li>пройдёте 10 бонусных курсов: от&nbsp;Agile и&nbsp;машинного обучения до&nbsp;моделирования <nobr>бизнес-процессов</nobr>, поддержания <nobr>work-life</nobr> balance и&nbsp;избавления от&nbsp;рутины;</li>
					<li>получите 3 индивидуальные консультации с&nbsp;<nobr>HR-экспертом</nobr> по&nbsp;развитию карьеры в&nbsp;аналитике;</li>
					<li>сходите на&nbsp;9 <nobr>онлайн-встреч</nobr> с&nbsp;практикующими экспертами из&nbsp;Datalatte, &laquo;Работа.ру&raquo; и&nbsp;SberDevices&nbsp;&mdash; для глубокого погружения в&nbsp;профессию;</li>
					<li>пройдёте курс по&nbsp;нейросетям и&nbsp;научитесь использовать передовые технологии в&nbsp;работе.</li>
				</ul>
				<p>Получите свой максимум для профессионального роста&nbsp;&mdash; <span class="bold-font">на&nbsp;специальных условиях для вас &#128071;</span></p>`
	},
	{
		"url": "academy-business-analyst",
		"course": "Апгрейд курса до PRO-тарифа",
		"progress": 15,
		"text": `<p class="bold-font">Вы&nbsp;прошли 15% обучения&nbsp;&mdash; так держать!</p>
				<p>Мы&nbsp;подготовили для вас секретное предложение по&nbsp;<span class="bold-font">апгрейду курса до&nbsp;<nobr>PRO-тарифа</nobr></span>! Расширьте свои карьерные возможности и&nbsp;уверенно начните карьеру в&nbsp;сфере <nobr>бизнес-анализа</nobr>.</p>
				<p class="bold-font">На&nbsp;тарифе PRO вы&nbsp;дополнительно:</p>
				<ul>
					<li>пройдёте 3 бонусных курса: изучите <nobr>soft-skills</nobr>, Python для анализа данных и&nbsp;продвинутый Power BI;</li>
					<li>сходите на&nbsp;3 <nobr>онлайн-встречи</nobr> с&nbsp;практиками: экспертом из&nbsp;&laquo;БКС Банка&raquo; и&nbsp;основателем Московской школы <nobr>бизнес-анализа</nobr>;</li>
					<li>получите индивидуальную консультацию с&nbsp;<nobr>HR-специалистом</nobr> по&nbsp;карьере в&nbsp;<nobr>бизнес-анализе</nobr>;</li>
					<li>найдёте работу, или деньги за&nbsp;обучение вернут&nbsp;&mdash; это зафиксировано в&nbsp;договоре.</li>
				</ul>
				<p class="bold-font">Специальные условия для вас&#128071;</p>`
	},
	{
		"url": "academy-business-analyst-pro",
		"course": "Продвинутый курс «Бизнес-аналитик. Мастер»",
		"progress": 15,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 15% курса &laquo;<nobr>Бизнес-аналитик</nobr>. PRO&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;<span class="bold-font">продвинутый курс &laquo;<nobr>Бизнес-аналитик</nobr>. Мастер&raquo;</span>.</p>
				<p class="bold-font">На&nbsp;нём вы:</p>
				<ul>
					<li>получите 6 бонусных курсов&nbsp;&mdash; по&nbsp;<nobr>soft-skills</nobr>, языку Python для анализа данных, продвинутому Power BI, основам статистики, разведочному анализу данных и&nbsp;основам программирования;</li>
					<li>пройдёте 3 консультации с&nbsp;<nobr>HR-специалистом</nobr> по&nbsp;вопросам карьеры в&nbsp;аналитике и&nbsp;выстроите персональный трек развития;</li>
					<li>поучаствуете в&nbsp;9 встречах с&nbsp;опытными практиками&nbsp;&mdash; экспертом из&nbsp;&laquo;БКС Банка&raquo; и&nbsp;основателем Московской Школы <nobr>Бизнес-анализа</nobr>.</li>
				</ul>
				<p>Готовы сделать мощный рывок в&nbsp;своей карьере? <span class="bold-font">Нажмите кнопку ниже и&nbsp;начните путь в&nbsp;высшую лигу аналитики.</span></p>`
	},
	{
		"url": "academy-systems-analyst",
		"course": "Продвинутый курс «Системный аналитик. PRO»",
		"progress": 15,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 15% курса &laquo;Системный аналитик. Базовый&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;<span class="bold-font">продвинутый курс &laquo;Системный аналитик. PRO&raquo;</span>.</p>
				<p class="bold-font">На&nbsp;курсе вы:</p>
				<ul>
					<li>получите персональные рекомендации от&nbsp;экспертов с&nbsp;опытом в&nbsp;системном и&nbsp;<nobr>бизнес-анализе</nobr>, быстрее освоите ключевые навыки аналитика&nbsp;&mdash; и&nbsp;примените их&nbsp;на&nbsp;практике;</li>
					<li>составите идеальное резюме, подготовитесь к&nbsp;собеседованиям и&nbsp;определите перспективные возможности трудоустройства&nbsp;&mdash; с&nbsp;<nobr>HR-специалистом</nobr> в&nbsp;сфере системного анализа;</li>
					<li>получите поддержку в&nbsp;трудоустройстве: если вы&nbsp;не&nbsp;найдёте работу&nbsp;&mdash; мы&nbsp;вернём деньги за&nbsp;обучение.</li>
				</ul>
				<p class="bold-font">Нажмите кнопку ниже и&nbsp;сделайте мощный рывок в&nbsp;своей карьере.</p>`
	},
	{
		"url": "academy-analytics-for-managers",
		"course": "Продвинутый курс «Аналитика для руководителей. PRO»",
		"progress": 15,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 15% курса &laquo;Аналитика для руководителей. Базовый&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;<span class="bold-font">продвинутый курс &laquo;Аналитика для руководителей. PRO&raquo;</span>.</p>
				<p class="bold-font">На&nbsp;курсе вы:</p>
				<ul>
					<li>за&nbsp;60 часов практики освоите навыки интерпретации аналитических показателей, чтобы принимать обоснованные управленческие решения;</li>
					<li>изучите проверенные методики внедрения <nobr>data-driven</nobr> культуры и&nbsp;повышения эффективности аналитики в&nbsp;<nobr>бизнес-процессах</nobr>;</li>
					<li>получите рекомендации от&nbsp;эксперта по&nbsp;оптимизации аналитики и&nbsp;составите пошаговый план вашего развития.</li>
				</ul>
				<p class="bold-font">Нажмите кнопку ниже и&nbsp;сделайте мощный рывок в&nbsp;своей карьере.</p>`
	},
	{
		"url": "academy-analytics-for-managers-pro",
		"course": "Продвинутый курс «Аналитика для руководителей. Мастер»",
		"progress": 15,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 15% курса &laquo;Аналитика для руководителей. PRO&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;<span class="bold-font">продвинутый курс &laquo;Аналитика для руководителей. Мастер&raquo;</span>.</p>
				<p class="bold-font">На&nbsp;курсе вы:</p>
				<ul>
					<li>начнёте использовать ещё больше фреймворков по&nbsp;работе с&nbsp;аналитикой в&nbsp;компании;</li>
					<li>получите разбор вашей текущей ситуации от&nbsp;эксперта и&nbsp;внедрите передовые методики в&nbsp;ваш бизнес&nbsp;&mdash; эксперт учтёт индивидуальные особенности вашей компании и&nbsp;подстроит рекомендации под ваши потребности.</li>
				</ul>
				<p class="bold-font">Нажмите кнопку ниже и&nbsp;сделайте мощный рывок в&nbsp;своей карьере.</p>`
	},
	{
		"url": "academy-analytics-tools",
		"course": "Курсы «Аналитик данных. Базовый» и «Аналитика для руководителей. Базовый»",
		"progress": 15,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 15% курса &laquo;Инструменты аналитика данных&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курсы &laquo;Аналитик данных. Базовый&raquo; и&nbsp;&laquo;Аналитика для руководителей. Базовый&raquo;.</p>
				<p class="bold-font">На&nbsp;курсе &laquo;Аналитик данных. Базовый&raquo; вы:</p>
				<ul>
					<li>за&nbsp;6&nbsp;месяцев поймёте, как решать реальные рабочие задачи, научитесь работать со&nbsp;сложными практическими кейсами;</li>
					<li>сформируете 8 кейсов для портфолио;</li>
					<li>получите доступ к&nbsp;24 курсам по&nbsp;разным аспектам аналитики данных;</li>
					<li>пройдёте дополнительное обучение по&nbsp;методологии Agile;</li>
					<li>отработаете навыки по&nbsp;Python и&nbsp;SQL на&nbsp;интерактивном тренажёре;</li>
					<li>получите диплом о&nbsp;профессиональной переподготовке и&nbsp;будете на&nbsp;связи с&nbsp;куратором целый год с&nbsp;момента старта обучения.</p>
				</ul>
				<p class="bold-font">На&nbsp;курсе &laquo;Аналитика для руководителей. Базовый&raquo; вы:</p>
				<ul>
					<li>освоите эффективные методики использования данных для принятия обоснованных <nobr>бизнес-решений</nobr>&nbsp;&mdash; от&nbsp;основ до&nbsp;продвинутого финансового и&nbsp;продуктового анализа;</li>
					<li>изучите проверенные фреймворки для интеграции аналитики в&nbsp;компанию.</li>
				</ul>
				<p class="bold-font">Нажмите кнопку ниже и&nbsp;сделайте мощный рывок в&nbsp;своей карьере.</p>`
	},
	{
		"url": "academy-power-bi",
		"course": "Курсы «Аналитик данных. Базовый» и «Аналитика для руководителей. Базовый»",
		"progress": 15,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 15% курса &laquo;Power BI&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курсы &laquo;Аналитик данных. Базовый&raquo; и&nbsp;&laquo;Аналитика для руководителей. Базовый&raquo;</span>.</p>
				<p class="bold-font">На&nbsp;курсе &laquo;Аналитик данных. Базовый&raquo; вы:</p>
				<ul>
					<li>за&nbsp;6&nbsp;месяцев поймёте, как решать реальные рабочие задачи, научитесь работать со&nbsp;сложными практическими кейсами;</li>
					<li>сформируете 8 кейсов для портфолио;</li>
					<li>получите доступ к&nbsp;24 курсам по&nbsp;разным аспектам аналитики данных;</li>
					<li>пройдёте дополнительное обучение по&nbsp;методологии Agile;</li>
					<li>отработаете навыки по&nbsp;Python и&nbsp;SQL на&nbsp;интерактивном тренажёре;</li>
					<li>получите диплом о&nbsp;профессиональной переподготовке и&nbsp;будете на&nbsp;связи с&nbsp;куратором целый год с&nbsp;момента старта обучения.</p>
				</ul>
				<p class="bold-font">На&nbsp;курсе &laquo;Аналитика для руководителей. Базовый&raquo; вы:</p>
				<ul>
					<li>освоите эффективные методики использования данных для принятия обоснованных <nobr>бизнес-решений</nobr>&nbsp;&mdash; от&nbsp;основ до&nbsp;продвинутого финансового и&nbsp;продуктового анализа;</li>
					<li>изучите проверенные фреймворки для интеграции аналитики в&nbsp;компанию.</li>
				</ul>
				<p class="bold-font">Нажмите кнопку ниже и&nbsp;сделайте мощный рывок в&nbsp;своей карьере.</p>`
	},
	{
		"url": "academy-sql",
		"course": "Курсы «Аналитик данных. Базовый» и «Аналитика для руководителей. Базовый»",
		"progress": 15,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 15% курса &laquo;SQL с&nbsp;нуля для анализа данных&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;<span class="bold-font">курсы &laquo;Аналитик данных. Базовый&raquo; и&nbsp;&laquo;Аналитика для руководителей. Базовый&raquo;</span>.</p>
				<p class="bold-font">На&nbsp;курсе &laquo;Аналитик данных. Базовый&raquo; вы:</p>
				<ul>
					<li>за&nbsp;6&nbsp;месяцев поймёте, как решать реальные рабочие задачи, научитесь работать со&nbsp;сложными практическими кейсами;</li>
					<li>сформируете 8 кейсов для портфолио;</li>
					<li>получите доступ к&nbsp;24 курсам по&nbsp;разным аспектам аналитики данных;</li>
					<li>пройдёте дополнительное обучение по&nbsp;методологии Agile;</li>
					<li>отработаете навыки по&nbsp;Python и&nbsp;SQL на&nbsp;интерактивном тренажёре;</li>
					<li>получите диплом о&nbsp;профессиональной переподготовке и&nbsp;будете на&nbsp;связи с&nbsp;куратором целый год с&nbsp;момента старта обучения.</p>
				</ul>
				<p class="bold-font">На&nbsp;курсе &laquo;Аналитика для руководителей. Базовый&raquo; вы:</p>
				<ul>
					<li>освоите эффективные методики использования данных для принятия обоснованных <nobr>бизнес-решений</nobr>&nbsp;&mdash; от&nbsp;основ до&nbsp;продвинутого финансового и&nbsp;продуктового анализа;</li>
					<li>изучите проверенные фреймворки для интеграции аналитики в&nbsp;компанию.</li>
				</ul>
				<p class="bold-font">Нажмите кнопку ниже и&nbsp;сделайте мощный рывок в&nbsp;своей карьере.</p>`
	},
	{
		"url": "academy-invest-analyst",
		"course": "Инвестиционный аналитик",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Перейдите на&nbsp;программу <span class="bold-font">&laquo;Инвестиционный директор&raquo;</span> на&nbsp;выгодных условиях, чтобы занять стратегическую роль и&nbsp;взять под свой контроль управление инвестиционным портфелем. Программа соответствует Профстандарту 08.036 &mdash;</p>
				<p>Бонусом к&nbsp;текущему курсу вы:</p>
				<ul>
					<li>освоите современный стратегический менеджмент;</li>
					<li>научитесь применять подходы классического стратегического менеджмента;</li>
					<li>изучите инструменты стратегического менеджмента для решения <nobr>бизнес-задач</nobr>;</li>
					<li>разберетесь в&nbsp;финансировании инвестиционных проектов и&nbsp;научитесь оценивать их&nbsp;рентабельность;</li>
					<li>получите знания об&nbsp;отраслевых особенностях ведения бизнеса в&nbsp;IT, производстве, торговле, строительстве и&nbsp;HoReCa;</li>
					<li>научитесь эффективно управлять командой и&nbsp;развивать лидерские навыки;</li>
					<li>oсвоите основы статистики и&nbsp;теории вероятности для анализа данных.</li>
				</ul>
				<p class="bold-font">Зарабатывайте больше после продвинутого курса на&nbsp;специальных условиях&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-economist-financier",
		"course": "Экономист-финансист",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Перейдите на&nbsp;программу <span class="bold-font">&laquo;Директор по&nbsp;экономике и&nbsp;финансам&raquo;</span> на&nbsp;выгодных условиях, чтобы занять стратегическую роль и&nbsp;взять под свой контроль рентабельность бизнеса и&nbsp;работу <nobr>финансово-экономических</nobr> служб.</p>
				<p>Бонусом к&nbsp;текущему курсу вы:</p>
				<ul>
					<li>освоите основы стратегического менеджмента для эффективного управления бизнесом;</li>
					<li>научитесь разбираться в&nbsp;правовых аспектах ведения бизнеса;</li>
					<li>изучите подходы к&nbsp;управлению рисками и&nbsp;разработке стратегий их&nbsp;минимизации;</li>
					<li>получите навыки привлечения инвестиций и&nbsp;проведения сделок слияния и&nbsp;поглощения;</li>
					<li>станете принимать взвешенные управленческие решения в&nbsp;различных ситуациях;</li>
					<li>научитесь эффективно управлять продажами, закупками и&nbsp;логистикой для повышения результатов бизнеса.</li>
				</ul>
				<p class="bold-font">Зарабатывайте больше после продвинутого курса на&nbsp;специальных условиях&nbsp;&mdash; вам они понравятся &#128071;</p>`
	},
	{
		"url": "academy-director-of-education",
		"course": "Директор по обучению",
		"progress": 30,
		"text": `<p class="bold-font">Поздравляем, вы&nbsp;прошли 30% курса &laquo;Директор по&nbsp;обучению персонала&raquo;!</p>
				<p>Для вас мы&nbsp;подготовили закрытое предложение по&nbsp;переходу на&nbsp;продвинутый курс <span class="bold-font">&laquo;<nobr>Mini-MBA</nobr>: Обучение и&nbsp;развитие персонала&raquo;</span>.</p>
				<p>На&nbsp;нём вы:</p>
				<ul>
					<li>научитесь отстаивать интересы департамента перед собственником;</li>
					<li>научитесь применять <nobr>data-driven</nobr> подход в&nbsp;корпоративном обучении;</li>
					<li>самостоятельно выстроите стратегию корпоративного обучения, которая будет отвечать <nobr>бизнес-задачам</nobr>;</li>
					<li>программа разрабатывается прямо сейчас и&nbsp;есть шанс получить самые свежие знания с&nbsp;минимальными вложениями.</li>
				</ul>
				<p class="bold-font">Готовы сделать мощный рывок в&nbsp;своей карьере? Нажмите кнопку ниже и&nbsp;начните путь в&nbsp;высшую лигу <nobr>HR-директоров</nobr>.</p>`
	},
	{
		"url": "academy-salesman",
		"course": "Менеджер отдела продаж",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;программу <span class="bold-font">&laquo;Руководитель отдела продаж&raquo;</span>, чтобы вы&nbsp;получили ещё больше карьерных преимуществ.</p>
				<p>Вам откроются дополнительные уроки по&nbsp;управлению отделом продаж, после которых вы&nbsp;сможете с&nbsp;уверенностью занимать самую высокую должность.</p>
				<p class="bold-font">Для вас&nbsp;&mdash; особые условия &#128071;</p>`
	},
	{
		"url": "academy-ceo",
		"course": "СЕО",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;программу <span class="bold-font">&laquo;MBA: Собственник бизнеса&raquo;</span>.</p>
				<p class="bold-font">Что вы&nbsp;получите на&nbsp;курсе:</p>
				<ul>
					<li>Самую обширную программу в&nbsp;Академии с&nbsp;250+ часами контента</li>
					<li>3 консультации и&nbsp;<nobr>бизнес-разборы</nobr> с&nbsp;экспертами</li>
					<li>Глубокие навыки в&nbsp;управлении бизнесом: стратегия, процессы, инвестиции и&nbsp;масштабирование</li>
					<li>Практические инструменты по&nbsp;маркетингу, работе с&nbsp;данными (PowerBI, нейросети) и&nbsp;юридическим аспектам</li>
					<li>Отраслевая экспертиза: IT, строительство, торговля и&nbsp;производство</li>
				</ul>
				<p class="bold-font">Для вас&nbsp;&mdash; особые условия &#128071;</p>`
	},
	{
		"url": "academy-entrepreneur-start",
		"course": "Основы предпринимательства: старт бизнеса",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;программу <span class="bold-font">&laquo;MBA: Собственник бизнеса&raquo;</span>.</p>
				<p class="bold-font">Что вы&nbsp;получите на&nbsp;курсе:</p>
				<ul>
					<li>Самую обширную программу в&nbsp;Академии с&nbsp;250+ часами контента</li>
					<li>3 консультации и&nbsp;<nobr>бизнес-разборы</nobr> с&nbsp;экспертами</li>
					<li>Глубокие навыки в&nbsp;управлении бизнесом: стратегия, процессы, инвестиции и&nbsp;масштабирование</li>
					<li>Практические инструменты по&nbsp;маркетингу, работе с&nbsp;данными (PowerBI, нейросети) и&nbsp;юридическим аспектам</li>
					<li>Отраслевая экспертиза: IT, строительство, торговля и&nbsp;производство</li>
				</ul>
				<p class="bold-font">Для вас&nbsp;&mdash; особые условия &#128071;</p>`
	},
	{
		"url": "academy-team-management",
		"course": "Управление командами",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;программу <span class="bold-font">&laquo;MBA: Лидерство&raquo;</span>.</p>
				<p class="bold-font">Вы&nbsp;получите 64 урока на&nbsp;47 часов по&nbsp;темам:</p>
				<ul>
					<li>Лидерство и&nbsp;управление изменениями</li>
					<li>Личная эффективность</li>
					<li>Управление сотрудниками и&nbsp;командой</li>
					<li>Управление дистанционной командой</li>
					<li>Коучинг как лидерская технология</li>
				</ul>
				<p class="bold-font">Для вас&nbsp;&mdash; особые условия &#128071;</p>`
	},
	{
		"url": "academy-soft-skills",
		"course": "Soft skills для руководителя",
		"progress": 30,
		"text": `<p class="bold-font">Треть обучения позади&nbsp;&mdash; вы&nbsp;опережаете 80% пользователей!</p>
				<p>Мы&nbsp;подготовили для вас специальное предложение по&nbsp;переходу на&nbsp;программу <span class="bold-font">&laquo;MBA: Лидерство&raquo;</span>.</p>
				<p class="bold-font">Вы&nbsp;получите 64 урока на&nbsp;47 часов по&nbsp;темам:</p>
				<ul>
					<li>Лидерство и&nbsp;управление изменениями</li>
					<li>Личная эффективность</li>
					<li>Управление сотрудниками и&nbsp;командой</li>
					<li>Управление дистанционной командой</li>
					<li>Коучинг как лидерская технология</li>
				</ul>
				<p class="bold-font">Для вас&nbsp;&mdash; особые условия &#128071;</p>`
	}
]

export default upsellPopups;
